<template>
	<div class="financialinstitutions">
		<div class="financialinstitutions_top">
			<div class="financialinstitutions_mybreadcrumb">
				当前位置： <app-mybreadcrumb />
			</div>

			<div class="financialinstitutions_inp">
				<el-input placeholder="输入关键字搜索" suffix-icon="el-icon-search" v-model="keyword" @change='searchData'></el-input>
			</div>
		</div>

		<div class="financialinstitutions_select">
			<div class="financialinstitutions_select_left">
				<div class="financialinstitutions_select_card">
					<div class="financialinstitutions_select_font">所在地区</div>
					<div class="financialinstitutions_select__sele">
						<el-select v-model="value1" placeholder="请选择" clearable @clear='clearClass'>
							<el-option v-for="item in options1" :value="item.name" @click.native='setAddressId(item.id)'>
							</el-option>
						</el-select>
					</div>
				</div>

				<div class="financialinstitutions_select_card">
					<div class="financialinstitutions_select_font">机构性质</div>
					<div class="financialinstitutions_select__sele">
						<el-select v-model="value2" placeholder="全部" clearable @clear='clearId'>
							<el-option v-for="item in options2" :label="item.name" :value="item.id" @click.native='setType(item)'>
							</el-option>
						</el-select>
					</div>
				</div>
			</div>

			<div class="financialinstitutions_select_rig">
				<div class="financialinstitutions_select_line financialinstitutions_select_font" @click='defaultData'>默认排序</div>

				<div class="financialinstitutions_select_line" style="margin-right: 20px;">
					<div class="financialinstitutions_select_font1">按产品数量排序</div>

					<div class="financialinstitutions_select_icon">
						<i class="el-icon-arrow-up financialinstitutions_weight" :class="{financialinstitutions_color: className == 1}" @click='changeNumSele(1)'></i>
						<i class="el-icon-arrow-down financialinstitutions_weight" :class="{financialinstitutions_color: className == 2}" @click='changeNumSele(2)'></i>
					</div>
				</div>

				<div class="financialinstitutions_select_line">
					<div class="financialinstitutions_select_font1">按产品成交数量排序</div>

					<div class="financialinstitutions_select_icon">
						<i class="el-icon-arrow-up financialinstitutions_weight" :class="{financialinstitutions_color: className == 3}" @click='changeNumSele(3)'></i>
						<i class="el-icon-arrow-down financialinstitutions_weight" :class="{financialinstitutions_color: className == 4}" @click='changeNumSele(4)'></i>
					</div>
				</div>
			</div>
		</div>

		<div class="financialinstitutions_list" v-if='flag == 1'>
			<div class="financialinstitutions_item" v-for='item in listsItem' @click='toagencydetail(item.id)'>
				<div class="institutions_pic_box">
					<el-image :src="item.finance_trade" class='institutions_pic'></el-image>
				</div>

				<section class="financialinstitutions_item_rig">
					<div class="financialinstitutions_item_user">
						<div class="financialinstitutions_item_name" :title='item.name'>{{item.name}}</div>
						<div class="financialinstitutions_item_jianjie" :title='item.introduce'>{{item.introduce}}</div>
					</div>


					<div class="financialinstitutions_information">
						<div class="financialinstitutions_information_item">
							<div class="financialinstitutions_information_num"><span style="margin-right: 3px;">{{item.pro_num}}</span>个</div>
							<div class="financialinstitutions_information_bm">
								<img src="../../assets/image/2.png" class="financialinstitutions_icon" />
								<div class="financialinstitutions_information_font">产品数量</div>
							</div>
						</div>

						<div class="financialinstitutions_information_item">
							<div class="financialinstitutions_information_num"><span style="margin-right: 3px;">{{item.suss_num}}</span>次</div>
							<div class="financialinstitutions_information_bm">
								<img src="../../assets/image/5.png" class="financialinstitutions_icon" />
								<div class="financialinstitutions_information_font">成交单数</div>
							</div>
						</div>

						<div class="financialinstitutions_information_item">
							<div class="financialinstitutions_information_num"><span style="margin-right: 3px;">{{item.ask_num}}</span>家</div>
							<div class="financialinstitutions_information_bm">
								<img src="../../assets/image/1.png" class="financialinstitutions_icon" />
								<div class="financialinstitutions_information_font">申请次数</div>
							</div>
						</div>
					</div>
				</section>

			</div>

			<!-- 分页 -->
			<div class="financialinstitutions_list_reportscreen">
				<div class="financialinstitutions_list_footer">
			        <div class="financialinstitutions_list_mar">
			        	<app-mypaginations :pagSize='paginations' :allData='institutionsList' @setHandleCurrent='handleCurrent' @setHandleSize='handleSize' @setPage='setPage' @setSize='setSize' />
			        </div>
		        </div>
			</div>
		</div>

		<el-empty description="数据为空" v-if='flag == 0' :image-size="200"></el-empty>
	</div>
</template>

<script>
	import MyBreadcrumb from '@/components/MyBreadcrumb/mybreadcrumb'
	import MyPaginations from '@/components/MyPaginations/mypaginations.vue'

	export default {
		data() {
			return {
				paginations: {
		          page_index: 1, // 当前位于哪页
		          total: 10, // 总数
		          page_size: 10, // 1页显示多少条
		          page_sizes: [10, 20, 50, 90], //每页显示多少条
		          layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
		        },
				keyword: '',
				value1: '',
				value2: '',
				value3: '按产品数量排序',
				value4: '按产品成交数量排序',
				options1: [],
				options2: [],
			    cityListProps: {
			         value: "id", //匹配响应数据中的id
			         label: "name", //匹配响应数据中的name
			         children: "chilren", //匹配响应数据中的children
			    },
				institutionsList: [],
				listsItem: [],
				className: -1,
				type_id: '',
				order_pro: '',
				pro_req: '',
				flag: 1,
				addressId: '',
				count: 0
			}
		},
		components: {
			'app-mybreadcrumb': MyBreadcrumb,
			'app-mypaginations': MyPaginations
		},
		methods: {
			getCompanyType() {
		      let data = {}

		      this.$post('/finance_type', data).then(res => {
		        if(res.data.status == 1) {
		          this.options2 = res.data.result
		        }
		      })
		    },
			setType(item) {
				this.type_id = item.id
				this.paginations.page_index = 1

				this.getData()
			},
			setPage(data) {
				this.paginations.page_index = data

				this.getData()
			},
			setSize(data) {
				this.paginations.page_index = 1
				this.paginations.page_size = data

				this.getData()
			},
			handleCurrent(item) {
		    	this.listsItem = item
		    },
		    handleSize(item) {
		    	this.listsItem = item
		    },
		    //重置分页
		    resetPaginations() {
		      // 总页数
		      this.paginations.total = this.count
		      // 设置默认分页数据
		      this.listsItem = this.institutionsList.filter((item, index) => {
		        return index < this.paginations.page_size
		      })
		    },
		    toagencydetail(id) {
		    	this.$router.push({path: '/agencydetails', query: {ids: id}})
		    },
		    clearId() {
		    	this.type_id = ''

		    	this.paginations.page_index = 1

		    	this.getData()
		    },
		    changeNumSele(num) {
		    	this.className = num

		    	if(num == 1) {
		    		this.order_pro = 1

		    		this.pro_req = ''
		    	}

		    	if(num == 2) {
		    		this.order_pro = 2

		    		this.pro_req = ''
		    	}

		    	if(num == 3) {
		    		this.pro_req = 1

		    		this.order_pro = ''
		    	}

		    	if(num == 4) {
		    		this.pro_req = 2

		    		this.order_pro = ''
		    	}

		    	this.paginations.page_index = 1

		    	this.getData()
		    },
		    searchData() {
		    	this.paginations.page_index = 1

		    	this.getData()
		    },
		    // 适用地区
			getQhCityList() {
				let data = {}

				this.$post('/qh_city_lst', data).then(res => {
					this.options1 = res.data.result ? res.data.result : []
				})
			},
			clearClass() {
				this.addressId = ''

				this.getData()
			},
			setAddressId(id) {
				this.addressId = id

				this.paginations.page_index = 1

				this.getData()
			},
			defaultData() {
				this.value1 = ''
				this.value2 = ''
				
				this.paginations.page_index = 1
				this.keyword = ''
				this.addressId = ''
				this.order_pro = ''
				this.pro_req = ''
				this.type_id = ''
				this.className = -1

				this.getData()
			},
		    // 列表
		    getData() {
		    	let data = {
		    		page: this.paginations.page_index,
		    		limit: this.paginations.page_size,
		    		keyword: this.keyword,
		    		region_id: this.addressId,
		    		order_pro: this.order_pro,
		    		pro_req: this.pro_req,
		    		type: this.type_id
		    	}

		    	console.log(this.value1[0], this.value1[1])

		    	this.$post('/finance_list', data).then(res => {
		    		if(!res.data.result) {
						this.institutionsList = []
						this.count = 0
						
						this.flag = 0
					}else {
						this.institutionsList = res.data.result.length == 0 ? [] : res.data.result
						this.count = res.data.count
						
						this.flag = 1
					}

					this.resetPaginations()
		    	})
		    }
		},
		mounted() {
			this.getCompanyType()
			this.getData()
			this.getQhCityList()
		}
	}
</script>

<style scoped>
	@import './financialinstitutions.css';
</style>